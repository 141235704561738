//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.progressBar
{
    $height:       20px;

    height:        20px;
    border-radius: $height / 2;
    display:       flex;
    margin:        0 20px 0 0;
    background:    $colorWhite;
    overflow:      hidden;

    .progressBarProgress
    {
        overflow:    hidden;
        height:      100%;
        text-indent: -9999px;
    }

    &.progressBar0
    {
        .progressBarProgress
        {
            background: $colorGreen2;
        }
    }

    &.progressBarOver0
    {
        .progressBarProgress
        {
            background: $colorGreen2;
        }
    }

    &.progressBarOver25
    {
        .progressBarProgress
        {
            background: $colorGreen2;
        }
    }

    &.progressBarOver50
    {
        .progressBarProgress
        {
            background: $colorGreen2;
        }
    }

    &.progressBarOver75
    {
        .progressBarProgress
        {
            background: $colorYellow;
        }
    }

    &.progressBar100
    {
        .progressBarProgress
        {
            background: $colorRedDark;
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.overlayMenuButton
{
    height:          48px;
    background:      $colorGrayLight6;
    font-size:       14px;
    font-family:     $fontSourceSansPro;
    font-weight:     bold;
    text-decoration: none;
    cursor:          pointer;
    padding:         0 0 0 18px;
    line-height:     48px;

    &:hover
    {
        text-decoration: underline;
    }
}

.overlayMenuButtonDefault
{
    color: $colorGrayLight2;
}

.overlayMenuButtonRed
{
    color: $colorRed;
}

// @formatter:off
// Sorted by highest z-index to the lowest one
$zIndexLoadingOverlay:      13373000;
$zIndexControlDropdown:     13372070;
$zIndexOverlay:             13372000;
$zIndexControlArrowOverlay: 13371060;
$zIndexTableSortArrow:      13371050;
$zIndexDefault:             13371000;

:export {
    zIndexLoadingOverlay:      $zIndexLoadingOverlay;
    zIndexOverlay:             $zIndexOverlay;
    zIndexControlDropdown:     $zIndexControlDropdown;
    zIndexControlArrowOverlay: $zIndexControlArrowOverlay;
    zIndexTableSortArrow:      $zIndexTableSortArrow;
    zIndexDefault:             $zIndexDefault;
}
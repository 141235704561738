*
{
    margin:  0;
    padding: 0;
}

html
{
    height: 100%;
}

body
{
    display:                 flex;
    flex-direction:          column;
    height:                  100%;
    margin:                  0;
    padding:                 0;
    font-family:             -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                             "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                             sans-serif;
    -webkit-font-smoothing:  antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:global #root
{
    flex:           1;
    display:        flex;
    flex-direction: column;
}

code
{
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type=submit]
{
    display: none;
}

.contentView
{
    flex-direction:  column;
    width:           100%;
    display:         flex;
    flex:            1;
    justify-content: center;
    align-items:     flex-start;
}

.contentViewFullHeight
{
    height: 100%;
}

.flexView
{
    flex: 1;
}
//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.breadCrumbBar
{
    border-top: 1px solid $colorGrayLight3;
    margin:     0 0 11px 0;
    width:      100%;

    ul
    {
        margin:     0;
        background: $colorWhite;
        position:   relative;
        display:    inline;
        top:        -11px;
        padding:    0 6px 0 0;

        li
        {
            display: inline;

            &,
            a
            {
                color:           $colorGrayLight;
                font-size:       13px;
                text-decoration: none;
                font-family:     $fontSourceSansPro;
            }

            a
            {

                &:hover
                {
                    text-decoration: underline;
                }
            }

            &:after
            {
                content: ' > ';
            }

            &:last-child
            {
                &:after
                {
                    display: none;
                }
            }
        }
    }
}

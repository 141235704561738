//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// @formatter:off
$colorBlack80:    rgba(0, 0, 5, 0.8);
$colorBlue:       #0A9EBF;
$colorGrayDark:   #505050;
$colorGrayLight2: #6E756C;
$colorGrayLight3: #CCCCCC;
$colorGrayLight5: #7F7F7F;
$colorGrayLight6: #F5F5F5;
$colorGrayLight:  #ACACAC;
$colorGreen2:     #93D990;
$colorGreen:      #ABE6A8;
$colorGreenLight: #F6FCF6;
$colorPink:       #ca76a0;
$colorRed80:      rgba(238, 41, 49, 0.8);
$colorRed:        #EE2931;
$colorRedDark:    #CC2F36;
$colorRedLight:   #FAEAEB;
$colorWhite70:    rgba(255, 255, 255, 0.7);
$colorWhite:      #FFFFFF;
$colorYellow:     #F8DE51;

:export {
    colorGrayDark:   $colorGrayDark;
    colorGrayLight:  $colorGrayLight;
    colorGrayLight2: $colorGrayLight2;
    colorGrayLight3: $colorGrayLight3;
    colorGrayLight5: $colorGrayLight5;
    colorGrayLight6: $colorGrayLight6;
    colorGreen:      $colorGreen;
    colorGreenLight: $colorGreenLight;
    colorRed:        $colorRed;
    colorRed80:      $colorRed80;
    colorRedDark:    $colorRedDark;
    colorRedLight:   $colorRedLight;
    colorWhite:      $colorWhite;
    colorWhite70:    $colorWhite70;
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.iconBox
{
    height:          100%;
    width:           49px;
    background:      $colorRed;
    display:         flex;
    justify-content: flex-start;
    align-items:     flex-start;
    border-radius:   4px 0 0 4px;
    position:        absolute;
    top:             0;
    left:            0;

    span
    {
        margin: 17px 0 0 0;
    }
}

.iconBoxClickable
{
    cursor: pointer;
}

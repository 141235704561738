//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.scrollToTopButton
{
    display:       flex;
    border-radius: 7px;
    border:        2px solid $colorGrayLight2;
    height:        30px;
    width:         30px;
    cursor:        pointer;
    margin-left:   40px;
    background:    $colorGrayDark;
    transition:    background-color 0.1s linear;

    svg
    {
        *
        {
            fill: $colorGrayLight2;
        }
    }

    &:hover
    {
        background: $colorGrayLight2;

        svg
        {
            *
            {
                fill: $colorGrayDark;
            }
        }
    }
}

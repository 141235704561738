//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.leftRightSplitter
{
    $spaceInBetween: 100px;

    display:         flex;
    flex-direction:  row;
    width:           100%;

    .leftRightSplitterLeft,
    .leftRightSplitterRight
    {
        display: flex;
    }

    .leftRightSplitterLeft
    {
        margin-right: $spaceInBetween / 2;
    }

    .leftRightSplitterRight
    {
        margin-left: $spaceInBetween / 2;
    }

    .leftRightSplitterAlignCenter
    {
        align-items: center;
    }

    .leftRightSplitterAlignDefault
    {
        align-items: flex-start;
    }

    .leftRightSplitterLeft
    {
        justify-content: flex-start;
    }

    .leftRightSplitterRight
    {
        justify-content: flex-end;
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.dateInputWrapper
{
    box-sizing: border-box;
    display:    flex;
}

:global .react-datepicker
{
    font-family: $fontSourceSansPro !important;
}

:global .react-datepicker__header
{
    background-color: $colorWhite !important;
}

:global .react-datepicker-wrapper
{
    flex:    1;
    display: flex;
}

:global .react-datepicker__input-container
{
    flex:       1;
    display:    flex !important;
    box-sizing: border-box;
    padding:    0 9px 0 0;
}

:global .react-datepicker__day
{
    outline: none !important;
}

:global .react-datepicker__navigation
{
    outline: none !important;
}

:global .react-datepicker__navigation--previous,
:global .react-datepicker__navigation--next
{
    transition: border-color 0.1s linear;
}

:global .react-datepicker__navigation--previous
{
    border-right-color: $colorGrayLight !important;
}

:global .react-datepicker__navigation--next
{
    border-left-color: $colorGrayLight !important;
}

:global .react-datepicker__navigation--previous:hover
{
    border-right-color: $colorGrayLight5 !important;
}

:global .react-datepicker__navigation--next:hover
{
    border-left-color: $colorGrayLight5 !important;
}

:global .react-datepicker__day--selected,
:global .react-datepicker__day--in-selecting-range,
:global .react-datepicker__day--in-range,
:global .react-datepicker__month-text--selected,
:global .react-datepicker__month-text--in-selecting-range,
:global .react-datepicker__month-text--in-range,
:global .react-datepicker__quarter-text--selected,
:global .react-datepicker__quarter-text--in-selecting-range,
:global .react-datepicker__quarter-text--in-range,
:global .react-datepicker__day--keyboard-selected,
:global .react-datepicker__month-text--keyboard-selected,
:global .react-datepicker__quarter-text--keyboard-selected,
:global .react-datepicker__day--keyboard-selected:hover,
:global .react-datepicker__month-text--keyboard-selected:hover,
:global .react-datepicker__quarter-text--keyboard-selected:hover
{
    background-color: $colorRed !important;
}

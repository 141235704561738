//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.monthlyContainerContainer
{
    display:          flex;
    flex-direction:   column;
    height:           100%;
    width:            100%;
    border:           1px solid $colorGrayLight;
    border-radius:    5px;
    background-color: $colorRed;

    .monthlyContainerHeader
    {
        display:                 flex;
        flex-direction:          row;
        position:                relative;
        padding:                 10px;
        border-top-left-radius:  5px;
        border-top-right-radius: 5px;
        color:                   $colorWhite;
        font:                    $fontOverviewTitle;

        > span,
        > div
        {
            user-select: none;

            &:first-child,
            &:last-child
            {
                display: flex;
                flex:    1;

                &:first-child
                {
                    justify-content: flex-start;
                }

                &:last-child
                {
                    justify-content: flex-end;
                }
            }
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.labelContainer
{
    position: absolute;
    left:     110px;
    top:      0;
}

.dateLabel
{
    width: 105px;
}

.greyedOutDate
{
    overflow:   visible;
    opacity:    0.7;
    font-style: italic;
}
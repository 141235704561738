//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.headline
{
    &.headlineBold
    {
        color:       $colorGrayLight2;
        font-family: $fontSourceSansPro;
        font-size:   20px;
        font-weight: bold;
        margin:      0 0 24px 0;
    }

    &.headlineSmall
    {
        color:       $colorGrayLight2;
        font-family: $fontSourceSansPro;
        font-size:   19px;
        font-weight: no;
        margin:      0 0 24px 0;
    }

    &.headlineStandard,
    &.headlineStandardSmallMargin
    {
        font-size:   24px;
        font-family: $fontSourceSansPro;
        color:       $colorGrayLight5;
        margin:      0 0 45px 0;

        &.headlineStandardSmallMargin
        {
            margin-bottom: 20px;
        }
    }
}

.headlineOverlay
{
    position: absolute;
    top:      0;
    right:    0;
}

.headlineWrapper
{
    position: relative;
    width:    100%;
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.checkBoxWrapper
{
    display:         flex;
    flex-direction:  column;
    margin:          0 0 20px 0;
    justify-content: center;
}

.checkBoxWrapperNoMarginBottom
{
    margin-bottom: 0;
}

.checkBoxWrapperMarginRight
{
    margin-right: 15px;
}

.checkBoxWrapperClickArea
{
    cursor:         pointer;
    display:        flex;
    flex-direction: row;
}

.checkBoxWrapperLabel
{
    color:       $colorGrayLight;
    font-size:   13px;
    font-weight: normal;
    font-family: $fontSourceSansPro;
    margin:      0 0 0 14px;
    user-select: none;
}

.checkBox
{
    width:         15px;
    height:        15px;
    border:        1px solid $colorGrayLight3;
    border-radius: 4px;
}

.checkBoxChecked
{
    background:   $colorRed;
    color:        $colorWhite;
    border-color: $colorRed;

    span
    {
        height: 100%;
    }

    svg
    {
        height: 100%;
        width:  60%;
        margin: 0 0 0 0;

        *
        {
            fill: $colorWhite;
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.footerContainer
{
    height:     60px;
    position:   fixed;
    bottom:     0;
    left:       0;
    width:      100%;
    background: $colorGrayDark;
    display:    flex;

    &,
    *
    {
        font-family: $fontSourceSansPro;
        font-size:   10px;
        color:       $colorGrayLight;
    }

    .footerLeft,
    .footerRight
    {
        $sidePadding:    60px;

        flex:            1;
        flex-direction:  column;
        display:         flex;
        align-items:     flex-start;
        justify-content: center;

        &.footerLeft
        {
            text-align:   left;
            padding-left: $sidePadding;
        }

        &.footerRight
        {
            display:         flex;
            flex-direction:  row;
            align-items:     center;
            justify-content: flex-end;
            padding-right:   20px;
            text-align:      right;

            ul
            {
                display:    flex;
                list-style: none;

                li
                {
                    &:not(:last-child)
                    {
                        margin-right: 40px;
                    }
                }
            }
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
@import '../../styles/all';

.dashboard
{
    display:        flex;
    flex-direction: row;
    width:          100%;
    height:         100%;

    .dashboardLeftSide,
    .dashboardRightSide
    {
        flex:   1;
        margin: 20px;

        &.dashboardLeftSide
        {
            margin-left: 0;
        }

        &.dashboardRightSide
        {
            display:         flex;
            flex-direction:  column;
            justify-content: space-between;
            margin-right:    0;
        }
    }

    .dashboardNumberBoxContainer,
    .dashboardLatestTrackedTimeContainer,
    .dashboardTopProjectsContainer
    {
        &:not(:last-child)
        {
            margin-bottom: 40px;
        }

        &.dashboardNumberBoxContainer
        {
            display:         flex;
            flex-direction:  row;
            justify-content: space-between;

            .dashboardNumberBox
            {
                &:first-child
                {
                    margin-right: 10px;
                }
            }
        }

        &.dashboardLatestTrackedTimeContainer,
        &.dashboardTopProjectsContainer
        {
            flex: 1;
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.abbreviationLabel
{
    $size:          26px;

    text-transform: uppercase;
    background:     $colorWhite;
    color:          $colorGrayLight2;
    text-align:     center;
    height:         $size;
    width:          $size;
    border-radius:  $size /2;
    display:        inline-block;
    line-height:    $size;
    font-family:    $fontSourceSansPro;
    font-size:      13px;
    font-weight:    bold;
    text-indent:    1px;
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
@import '../../styles/all';

.tableWithHeaderTitleContainer
{
    display:        flex;
    flex-direction: column;
    height:         100%;
    border:         1px solid $colorGrayLight;
    border-radius:  5px;

    &.tableWithHeaderTitleThemeYellow
    {
        background-color: $colorYellow;
    }

    &.tableWithHeaderTitleThemeGreen
    {
        background-color: $colorGreen2;
    }

    .tableWithHeaderTitleHeader
    {
        padding:                 10px;
        border-top-left-radius:  5px;
        border-top-right-radius: 5px;
        color:                   $colorWhite;
        font:                    $fontOverviewTitle;
    }

    :global .react-grid-Container
    {
        .react-grid-Grid
        {
            min-height: unset !important;
        }

        .react-grid-Header
        {
            display: none !important;
        }

        .react-grid-Viewport
        {
            top: 0 !important;
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.holidayLabel
{
    font-size:        12px;
    font-family:      $fontSourceSansPro;
    color:            $colorWhite;
    background-color: $colorYellow;
    border-radius:    4px;
    padding:          4px 6px;
    margin-left:      10px;
}
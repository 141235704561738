//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.paragraph
{
    color:       $colorGrayLight2;
    font-size:   15px;
    font-family: $fontSourceSansPro;
    margin:      0 0 20px 0;
    line-height: 21px;

    a
    {
        color:           $colorRed;
        text-decoration: none;

        &:hover
        {
            text-decoration: underline;
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.textInputWrapper
{
    display:        flex;
    flex-direction: row;
    width:          100%;
    box-sizing:     border-box;
    min-height:     50px;
    margin:         0 0 20px 0;
    position:       relative;
    padding:        0 0 0 49px;

    &:hover
    {
        input,
        textarea
        {
            border-color: $colorRed;
        }
    }

    $inputPadding:  12px;

    input,
    textarea
    {
        flex:              1;
        border:            1px solid $colorGrayLight3;
        border-left-width: 0;
        border-radius:     0 4px 4px 0;
        outline:           none;
        transition:        border-color 0.1s linear;
        font-family:       $fontSourceSansPro;
        font-size:         14px;
        font-weight:       normal;
        padding:           0 $inputPadding;
        color:             $colorGrayLight2;

        &::placeholder
        {
            color: $colorGrayLight2;
        }

        &:hover,
        &:focus
        {
            border-color: $colorRed;
        }
    }

    textarea
    {
        height:     101px;
        resize:     vertical;
        padding:    $inputPadding;
        min-height: 54px;
    }
}

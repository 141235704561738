//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.alertBox
{
    padding:       12px 32px 12px 53px;
    border-radius: 4px;
    font-size:     15px;
    font-family:   $fontSourceSansPro;
    border-width:  1px;
    border-style:  solid;
    margin:        0 0 20px 0;
    position:      relative;
}

.alertBoxCloseButtonWrapper
{
    position: absolute;
    top:      10px;
    right:    10px;
}

.alertBoxError
{
    border-color: $colorRedDark;
    background:   $colorRedLight;
    color:        $colorRedDark;

    svg
    {
        *
        {
            fill: $colorRed !important;
        }
    }

    .alertBoxIcon
    {
        background: $colorRedDark;

        svg
        {
            margin: 1px 0 0 1px;

            *
            {
                fill: $colorWhite !important;
            }
        }
    }
}

.alertBoxIcon
{
    $size:           20px;

    width:           $size;
    height:          $size;
    border-radius:   $size / 2;
    position:        absolute;
    top:             50%;
    left:            18px;
    margin:          -($size /2) 0 0 0;
    display:         flex;
    justify-content: center;
    align-items:     center;
}

.alertBoxSuccess
{
    border-color: $colorGreen;
    background:   $colorGreenLight;
    color:        $colorGreen;

    svg
    {
        *
        {
            fill: $colorGreen !important;
        }
    }

    .alertBoxIcon
    {
        background: $colorGreen;

        svg
        {
            *
            {
                fill: $colorWhite !important;
            }
        }
    }
}

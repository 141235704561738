//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.table
{
    flex:     1;
    position: relative;
    width:    100%;
}

$tableHeaderHeight: 29px;

:global .boxed .react-grid-Container
{
    .react-grid-HeaderRow
    {
        .react-grid-HeaderCell:first-child
        {
            margin-left: 12px !important;
        }
    }
}

:global .react-grid-Container
{
    height: 100%;
    width:  100% !important;

    .react-grid-Main
    {
        outline: none;
        height:  100%;

        .react-grid-Grid
        {
            height: 100%;
            border: 0 !important;

            .react-grid-Header
            {
                background: $colorWhite;

                .react-grid-HeaderRow
                {
                    background: $colorWhite;
                    border:     0 !important;
                    overflow:   hidden !important;

                    .react-grid-HeaderCell
                    {
                        background:  $colorWhite;
                        font-family: $fontSourceSansPro;
                        font-size:   13px;
                        font-weight: normal;
                        color:       $colorGrayLight;
                        line-height: 27px;
                        padding:     3px 0 0 0;
                        position:    relative;
                        border:      0;

                        .react-grid-HeaderCell-sortable,
                        .widget-HeaderCell__value
                        {
                            display:     flex;
                            white-space: pre-wrap;
                            line-height: 15px;
                            padding:     4px 0 0 0;
                        }

                        .react-grid-HeaderCell-sortable
                        {
                            span
                            {
                                display: none;
                            }

                            &::before,
                            &::after
                            {
                                height:          7px;
                                width:           7px;
                                margin-left:     5px;
                                color:           $colorGrayLight;
                                font-size:       7px;
                                font-family:     $fontFontAwesome;
                                font-style:      normal;
                                font-weight:     900;
                                text-decoration: inherit;
                            }

                            &::before
                            {
                                content:    '\f077';
                                order:      2;
                                margin-top: -3px;
                            }

                            &::after
                            {
                                content:     '\f078';
                                order:       3;
                                margin-top:  3px;
                                margin-left: -7px;
                            }

                            &.react-grid-HeaderCell-sortable--ascending::before
                            {
                                color: $colorGrayDark;
                            }

                            &.react-grid-HeaderCell-sortable--descending::after
                            {
                                color: $colorGrayDark;
                            }
                        }
                    }
                }
            }

            .react-grid-Viewport
            {
                .react-grid-Canvas
                {
                    > div
                    {
                        // This targets the <div> around .react-grid-Row
                        > div
                        {
                            &:hover
                            {
                                // This will make the overlay menu visible
                                position: relative;
                                z-index:  1;
                            }
                        }
                    }

                    &::-webkit-scrollbar
                    {
                        display: none;
                    }

                    .rdg-selected
                    {
                        display: none;
                    }

                    .react-grid-Row
                    {
                        &.react-grid-Row--even,
                        &.react-grid-Row--odd
                        {
                            &:hover
                            {
                                .react-grid-Cell
                                {
                                    background: $colorGrayLight3 !important;
                                }
                            }
                        }

                        &.react-grid-Row--even
                        {
                            .react-grid-Cell
                            {
                                background: $colorGrayLight6 !important;
                            }
                        }

                        &.react-grid-Row--odd
                        {
                            .react-grid-Cell
                            {
                                background: $colorWhite !important;

                                .progressBar
                                {
                                    background: $colorGrayLight6 !important;
                                }

                                .abbreviationLabel
                                {
                                    background: $colorGrayLight6 !important;
                                }
                            }
                        }

                        .react-grid-Cell
                        {
                            background: transparent !important;
                            padding:    0;
                            border:     0;
                            transition: background-color 0.1s linear;

                            &:first-child
                            {
                                .react-grid-Cell__value
                                {
                                    padding-left: 12px;
                                }
                            }

                            .react-grid-Cell__value
                            {
                                padding:     0;
                                font-family: $fontSourceSansPro;
                                font-size:   15px;
                                color:       $colorGrayLight2;
                                overflow:    visible;

                                // @see https://github.com/adazzle/react-data-grid/issues/245
                                > div
                                {
                                    height: 18px;

                                    > span
                                    {
                                        > div
                                        {
                                            text-overflow: ellipsis;
                                            padding:       0 5px 0 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

:global .has-actions-column
{
    .react-grid-Container
    {
        .react-grid-Main
        {
            .react-grid-Grid
            {
                .react-grid-Viewport
                {
                    .react-grid-Canvas
                    {
                        .react-grid-Row
                        {
                            &.react-grid-Row--even,
                            &.react-grid-Row--odd
                            {
                                .react-grid-Cell
                                {
                                    &:last-child
                                    {
                                        $marginToLeft: 8px;

                                        background:    $colorWhite !important;
                                        border-left:   $marginToLeft solid $colorWhite;
                                        left:          -$marginToLeft;
                                        position:      relative;
                                    }
                                }

                                &:hover
                                {
                                    .react-grid-Cell
                                    {
                                        &:last-child
                                        {
                                            background: $colorWhite !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

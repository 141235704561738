//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.centerContainer
{
    $width:     467px;

    width:      $width;
    align-self: center;

    form
    {
        width: $width;
    }
}

.centerContainerCenterContent
{
    display:         flex;
    align-items:     flex-start;
    justify-content: center;
    flex-direction:  column;
}

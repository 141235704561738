//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.overlayMenuText
{
    background:      $colorWhite;
    font-size:       13px;
    font-family:     $fontSourceSansPro;
    font-weight:     normal;
    text-decoration: none;
    cursor:          pointer;
    height:          auto;
    padding:         10px 18px;
    line-height:     20px;
    white-space:     pre-wrap;
}

.overlayMenuTextDefault
{
    color: $colorGrayLight2;
}

.overlayMenuTextItalic
{
    font-style: italic;
    color:      $colorGrayLight;
}

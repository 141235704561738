//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.inputArrowControlWrapper
{
    position: relative;
}

.inputArrowControlArrowWrapper
{
    user-select: none;
    position:    absolute;
    top:         0;
    right:       0;
    width:       30px;
    height:      100%;
    box-sizing:  border-box;
    padding:     1px 1px 1px;
    z-index:     $zIndexControlArrowOverlay;

    .inputArrowControlArrowWrapperBottom,
    .inputArrowControlArrowWrapperTop
    {
        height:          50%;
        display:         flex;
        justify-content: center;
        align-items:     center;
        background:      $colorWhite;
        overflow:        hidden;

        svg
        {
            *
            {
                fill: $colorGrayLight3;
            }
        }

        &:hover
        {
            svg
            {
                *
                {
                    fill: $colorGrayLight;
                }
            }
        }

        &.inputArrowControlArrowWrapperBottom
        {
            border-radius: 0 0 4px 0;
        }

        &.inputArrowControlArrowWrapperTop
        {
            border-radius: 0 4px 0 0;
        }
    }
}

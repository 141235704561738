//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.mainRouter
{
    flex:       1;
    display:    flex;
    transition: filer 0.1s linear;
}

.mainRouterLoading
{
    filter: blur(3px);
}

.mainRouterContent
{
    padding:         40px 16px 70px 16px;
    display:         flex;
    justify-content: flex-start;
    align-items:     flex-start;
    flex:            1;
}

.mainSplitPane
{
    position: relative !important;
    top:      0 !important;
    left:     0 !important;
    flex:     1;
}

.mainRouterLeftSideBar
{
    padding: 29px 0 0 0;
    width:   100%;
}

.mainRouterLeftSideBarLinkWrapper
{
    position: relative;
}

.mainRouterLeftSideBarAddButtonWrapper
{
    position: absolute;
    top:      10px;
    right:    10px;
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.dateTimeInputWrapper
{
    display: flex;
    margin:  0 0 20px 0;

    .dateTimeInputWrapperCenter,
    .dateTimeInputWrapperLeft,
    .dateTimeInputWrapperRight
    {
        display:        flex;
        flex-direction: column;

        label
        {
            color:       $colorGrayLight;
            font-size:   13px;
            font-family: $fontSourceSansPro;
            margin:      0 0 7px 0;
            display:     block;
        }

        input
        {
            flex:               50px;
            border:             1px solid $colorGrayLight3;
            border-radius:      4px;
            outline:            none;
            transition:         border-color 0.1s linear;
            font-family:        $fontSourceSansPro;
            font-size:          14px;
            font-weight:        normal;
            padding:            0 12px;
            appearance:         none;
            -webkit-appearance: none;
            color:              $colorGrayLight2;
            -moz-appearance:    textfield;

            &::-webkit-inner-spin-button,
            &::-webkit-calendar-picker-indicator,
            &::-webkit-clear-button,
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button
            {
                display:            none;
                -webkit-appearance: none;
            }

            &::placeholder
            {
                color: $colorGrayLight2;
            }

            &:hover,
            &:focus
            {
                border-color: $colorRed;
            }
        }

        &.dateTimeInputWrapperCenter
        {
            flex: 8;

            input
            {

                height: 48px;
            }
        }

        &.dateTimeInputWrapperLeft
        {
            flex:       3;
            font-size:  13px;
            color:      $colorGrayLight2;
            box-sizing: border-box;
            text-align: right;
            padding:    40px 10px 0 0;
        }

        &.dateTimeInputWrapperRight
        {
            flex:            5;
            display:         flex;
            flex-direction:  row;
            justify-content: space-between;

            > div
            {
                width:          70px;
                display:        flex;
                flex-direction: column;
            }

            .dateTimeInputWrapperRightInputWrapper,
            .dateTimeInputWrapperRightSelectWrapper
            {
                height: 50px;

                &.dateTimeInputWrapperRightInputWrapper
                {
                    input
                    {
                        width:  43px;
                        height: 48px;
                    }
                }

                &.dateTimeInputWrapperRightSelectWrapper
                {
                    display: flex;
                }
            }
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.sideBarLink
{
    background:      $colorWhite;
    padding:         0 0 0 41px;
    height:          40px;
    text-transform:  uppercase;
    display:         block;
    text-decoration: none;
    line-height:     40px;
    color:           $colorGrayLight2;
    font-family:     $fontSourceSansPro;
    font-weight:     bold;
    transition:      background-color 0.1s linear;

    &:hover
    {
        background: $colorGrayLight3;
    }

    &.sideBarLinkActive
    {
        background: $colorGrayLight6;
    }
}

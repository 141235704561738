//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
@import '../../styles/all';

.dashboardNumberBox
{
    display:        flex;
    flex-direction: column;
    width:          100%;
    padding:        20px 25px;
    border-radius:  5px;
    font-family:    $fontSourceSansPro;
    color:          $colorWhite;

    &.dashboardNumberBoxLightBlue
    {
        background-color: $colorBlue;
    }

    &.dashboardNumberBoxPink
    {
        background-color: $colorPink;
    }

    .titleContainer
    {
        display:        flex;
        flex-direction: row;
        align-items:    center;

        span
        {
            font:        $fontOverviewTitle;
            white-space: nowrap;
            font-weight: bold;
        }

        hr
        {
            flex:        1;
            margin-left: 10px;
            border-top:  1px solid $colorWhite;
        }
    }

    .numberContainer
    {
        font-size:   80px;
        font-weight: bold;
        margin-top:  40px;
        text-align:  right;
    }

    .unitContainer
    {
        font-size:  18px;
        text-align: right;
        color:      $colorWhite70;
    }
}

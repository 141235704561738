//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.textButton
{
    font-size:   14px;
    font-family: $fontSourceSansPro;
    font-weight: normal;
    cursor:      pointer;
    display:     flex;
    white-space: nowrap;
    user-select: none;

    &:hover
    {
        text-decoration: underline;
    }

    svg
    {
        position: relative;
        left:     -2px;
        top:      1px;
    }
}

.textButtonGray
{
    color: $colorGrayLight2;

    svg
    {
        *
        {
            fill: $colorGrayLight2;
        }
    }
}

.textButtonRed
{
    color:       $colorRed;
    font-weight: bold;

    svg
    {
        *
        {
            fill: $colorRed;
        }
    }
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.overlayMenuWrapper
{
    $triangleSize: 12px;
    $borderRadius: 10px;

    z-index:       $zIndexOverlay;
    position:      absolute;
    top:           40px;
    right:         0;
    border-radius: $borderRadius;
    border:        2px solid $colorGrayLight3;
    display:       none;

    &:before
    {
        position:     absolute;
        top:          -$triangleSize;
        right:        20px;
        content:      "";
        display:      inline-block;
        width:        0;
        height:       0;
        border-style: solid;
        border-width: 0 $triangleSize $triangleSize $triangleSize;
        border-color: transparent transparent $colorGrayLight3 transparent;
    }

    .overlayMenu
    {
        $innerTriangleSize: $triangleSize - 3;

        &:before
        {
            position:     absolute;
            top:          -$innerTriangleSize;
            right:        23px;
            content:      "";
            display:      inline-block;
            width:        0;
            height:       0;
            border-style: solid;
            border-width: 0 $innerTriangleSize $innerTriangleSize $innerTriangleSize;
            border-color: transparent transparent $colorWhite transparent;
        }

        ul
        {
            list-style: none;
            min-width:  184px;
            max-width:  800px;

            li
            {
                overflow-wrap:      break-word;
                $innerBorderRadius: $borderRadius - 2;

                &:first-child
                {
                    border-top-left-radius:  $innerBorderRadius;
                    border-top-right-radius: $innerBorderRadius;
                }

                &:last-child
                {
                    border-bottom-left-radius:  $innerBorderRadius;
                    border-bottom-right-radius: $innerBorderRadius;
                }
            }
        }
    }
}

.overlayMenuWrapperVisible
{
    display: block;
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.colorButton
{
    border-radius:   4px;
    font-size:       14px;
    font-family:     $fontSourceSansPro;
    font-weight:     bold;
    padding:         11px 54px;
    cursor:          pointer;
    transition:      transform 0.1s linear;
    display:         flex;
    text-decoration: none;
    user-select:     none;

    &:hover
    {
        transform: scale(1.04);
    }

    > span
    {
        margin: 0 6px 0 0;
        width:  auto;
    }
}

.colorButton + .colorButton
{
    margin-left: 10px;
}

.colorButtonGray,
.colorButtonGraySmall
{
    background: $colorGrayLight6;
    color:      $colorGrayLight2;

    svg
    {
        *
        {
            fill: $colorGrayLight2;
        }
    }
}

.colorButtonRed,
.colorButtonRedSmall
{
    background: $colorRed;
    color:      $colorWhite;

    svg
    {
        *
        {
            fill: $colorWhite;
        }
    }
}

.colorButtonGraySmall,
.colorButtonRedSmall
{
    padding: 11px 22px;
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.headerContainer
{
    display:        flex;
    flex-direction: row;
    height:         60px;
    background:     $colorRed;
    font-family:    $fontSourceSansPro;

    &.stagingHeader
    {
        background: $colorBlue;
    }

    h1
    {
        font-size: 20px;
        color:     $colorWhite;
        cursor:    pointer;

        strong,
        span
        {
            display: inline-block;
            padding: 0 0 2px 0;
        }

        span
        {
            $spaceAroundPipe: 8px;

            font-weight:      normal;
            margin-left:      $spaceAroundPipe;
            padding-left:     $spaceAroundPipe;
            border-left:      1px solid $colorWhite;
        }

        strong
        {
            font-weight: bold;
        }
    }

    .headerCenter,
    .headerLeft,
    .headerRight
    {
        $sidePadding: 20px;

        &.headerCenter
        {
            text-align:      center;
            display:         flex;
            align-items:     center;
            justify-content: center;
            flex:            3;
            user-select:     none;
        }

        &.headerLeft,
        &.headerRight
        {
            align-items: center;

            &.headerLeft
            {
                flex:         1;
                display:      flex;
                padding-left: $sidePadding;
            }

            &.headerRight
            {
                flex:            1;
                padding-right:   $sidePadding;
                display:         flex;
                justify-content: flex-end;

                &:hover
                {
                    .overlayMenuWrapper
                    {
                        display: block;
                    }
                }

                .headerUsernameWrapper
                {
                    display:     flex;
                    align-items: center;
                    position:    relative;

                    .headerUsername
                    {
                        color:       $colorWhite;
                        font-size:   12px;
                        font-family: $fontSourceSansPro;
                        font-weight: bold;
                        padding:     0 0 0 10px;
                    }
                }
            }
        }
    }
}

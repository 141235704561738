//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.tableFilterInputWrapper
{
    position: relative;
    display:  flex;
    padding:  0 10px 0 0;

    .tableFilterInputIconWrapper
    {
        position:       absolute;
        height:         9px;
        width:          9px;
        top:            6px;
        right:          20px;
        pointer-events: none;

        svg
        {
            *
            {
                fill: $colorGrayLight2;
            }
        }
    }

    .tableFilterInput
    {
        $topBottomPadding: 4px;

        box-sizing:        border-box;
        width:             100%;
        border:            1px solid $colorGrayLight3;
        border-radius:     4px;
        outline:           none;
        transition:        border-color 0.1s linear;
        font-family:       $fontSourceSansPro;
        font-size:         13px;
        font-weight:       normal;
        padding:           $topBottomPadding 20px $topBottomPadding 7px;
        color:             $colorGrayLight2;

        &::placeholder
        {
            color: $colorGrayLight;
        }

        &:hover,
        &:focus
        {
            border-color: $colorRed;
        }
    }
}

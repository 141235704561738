//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.loadingOverlay
{
    display:    none;
    position:   absolute;
    top:        0;
    left:       0;
    width:      100%;
    height:     100%;
    background: $colorBlack80;
    z-index:    $zIndexLoadingOverlay;
}

@keyframes pulse_animation
{
    0%
    {
        box-shadow: 0 0 0 0 $colorRed80
    }
    100%
    {
        box-shadow: 0 0 0 200px transparent
    }
}

.loadingOverlayVisible
{
    display:  flex;
    position: fixed;
    width:    100%;
    height:   100%;
    overflow: hidden;
}

.loadingOverlayVisible .loadingOverlayPulse
{
    margin: auto;
}

.loadingOverlayVisible .loadingOverlayPulse span
{
    position:      absolute;
    margin-top:    -25px;
    margin-left:   -25px;
    width:         50px;
    height:        50px;
    border-radius: 50%;
    background:    $colorRed;
    animation:     pulse_animation 1.3s infinite;
}

.loadingOverlayVisible .loadingOverlayPulse span:nth-child(2)
{
    animation-delay: 0.3s;
}

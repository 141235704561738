//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

@import '../../styles/all';

.selectWrapper
{
    display:        flex;
    flex-direction: row;
    width:          100%;
    height:         50px;
    margin:         0 0 20px 0;
    position:       relative;
    padding:        0 0 0 49px;
}

.selectHighlightedText
{
    background:  none;
    color:       $colorGrayLight2;
    font-weight: bold;
}
